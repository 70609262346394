// import lazyLoading from '../lazyLoading';

export default {
  name: 'wdmschema',
  caption: 'Wdm schema',
  wdm16_id: '',
  icon: 'icon-settings',
  path: '/wdmschema',
  // component: lazyLoading('patient/index'),
  required: [],
  permissions: ['wisdom'],
  meta: {
    required: [],
    icon: 'fa-bars',
    expanded: false
  }
};
