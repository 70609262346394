// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor);
import VueNotifications from 'vue-notifications';
// options =======>>>>>>>>>>success, error, info, warn
import miniToastr from 'mini-toastr';
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({
  types: toastTypes
  /*  style: {
      'mini-toastr__notification': {
        'mini-toastr-notification__message': {
          'border-radius': '5px',
          color: 'red'
        }
      }
    } */
});

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb)
};
const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
import _nav from './_nav';
Vue.use(VueNotifications, options);
import BootstrapVue from 'bootstrap-vue';
import App from './App';
import router from './router';
import Snotify from 'vue-snotify';
Vue.use(Snotify);
import { sync } from 'vuex-router-sync';
import UserService from '@/services/users';
import LoginService from '@/services/login';
import Resource from 'vue-resource';
Vue.use(Resource);
/*
import NProgress from 'vue-nprogress';
const nprogress = new NProgress({ parent: 'body', template: '<div id="loading-container"><div class="loading_"></div><div id="loading-text">loading</div><div class="bar" role="bar"> <div class="peg"></div></div><div class="spinner" role="spinner"> <div class="spinner-icon"></div></div></div>' });
// const nprogress = new NProgress({ parent: '.nprogress-container' });
Vue.use(NProgress);
*/
var error_list = [];
import * as filters from './filters';
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

import store from './store';
sync(store, router);
const { state } = store;
import { API_BASE_URL } from '@/config';
import VueNativeSock from 'vue-native-websocket';

import VersionService from '@/services/version';
import auth from './auth';
auth.checkAuth();
auth.f_getWisdomeraSystemDocument();
// todo
// cssVars()
/*
window.addEventListener('error', function(event) {
  console.log('error: addEventListener ');
});
window.addEventListener('unhandledrejection', function(event) {
  console.log('unhandledrejection');
  //handle error here
  //event.promise contains the promise object
  //event.reason contains the reason for the rejection
});
*/
/*window.onerror = function(message, url, lineno, colno, error) {
  console.log('WisdomEra Frontend error => ', error.toString());
  console.log('WisdomEra Frontend Message: => ', message);
  console.log('WisdomEra Frontend Url: => ', url);
  console.log('WisdomEra Frontend lineno: => ', lineno);
  console.log('WisdomEra Frontend colno: => ', colno);
}*/

Vue.http.interceptors.push(function(request, next) {
  // Add JWT to all requests
  if (!request.s3) {
    request.headers.set('Authorization', auth.getAuthHeader());
    // console.log(store.getters.websocket);
    if (store.getters.websocket && store.getters.websocket.id) {
      // IF WE WANT TO SEND EXTRA PARAMETER, WE HAVE TWO WAYS.1- USE PARAMS LOCATION OF REQUEST, 2- ACCORDING TO METHOD.
      // Using method design
      /*
        if (['POST', 'PUT'].indexOf(request.method) !== -1) {
          let x = {};
          if (request.body && request.body !== null) {
            x = request.body;
            if (typeof request.body === 'object') {}
          }
          x.my_socket_id = store.getters.websocket.id.toString();
          request.body = JSON.stringify(x);
          console.log('request.body ', request.body);
        } else if (['GET', 'DELETE'].indexOf(request.method) !== -1) {
          if (request.query.indexOf('?') === -1) {
            request.query += '?my_socket_id=' + store.getters.websocket.id.toString();
          } else {
            request.query += '&my_socket_id=' + store.getters.websocket.id.toString();
          }
        }
      */
      // Using params
      request.params.my_socket_id = store.getters.websocket.id;
    }
  }
  // console.log(request);
  // Skip storing token refresh requests
  if (request.url !== 'api/token/refresh') {
    // this.request = request;
  }

  // Check for expired token response, if expired, refresh token and resubmit original request
  next(function(response) {
    // console.log('next, response', response);
    // console.log(request);
    if (request && request.body) {
      auth.checkUnauthorizedToken(response, JSON.parse(request.body));
    }
    if (!response.ok) {
      try {
        var jwt = localStorage.getItem('access_token');
        if (jwt) {
          let msg = response.url;
          if (error_list.indexOf(msg) === -1) {
            error_list.push(msg);
            let error_data = {
              'codeType': 'frontend',
              'type': 'log',
              'info': response.url,
              'sub_type': 'error',
              'typeError': 'frontend',
              'valueError': response.status
            };
            Vue.http.post(API_BASE_URL + 'log/frontend_log', error_data).then(response => {
              // success
            }, response => {
              // HERE WORKS WHEN WE DONT HAVE AN INTERNET CONNECTION
              console.log('Frontend error sending error. Possible internet connection problem');
            });
          }
        }
      } catch (err) {
        console.log('Vue.http.interceptors.push next: ', err);
      }
    }
    auth.checkExpiredToken(response).then(function(response) {
      return response;
    });
  }, response => {
    console.log('http request error: ', response)
  });
}.bind(this));

Vue.use(BootstrapVue)
const IS_LOCAL = process.env.NODE_ENV !== 'production';
if (IS_LOCAL) {
  Vue.config.devtools = true;
}
router.beforeEach((to, from, next) => {
  // console.log('to::::', to);
  // console.log('from::::', from);
  var jwt = localStorage.getItem('access_token');
  var ws_url = '';
  if (jwt) {
    // console.log('API_BASE_URL :::', API_BASE_URL);
    if (!Vue.prototype.$socket) {
      ws_url = 'ws' + API_BASE_URL.substr(4, API_BASE_URL.length) + 'ws';
      /*if (API_BASE_URL.indexOf('http') !== -1) {
        ws_url = 'ws://' + API_BASE_URL.split('//')[1] + 'ws';
      } else {
        ws_url = 'ws://localhost:8888/ws';
      }*/
      Vue.use(VueNativeSock, ws_url, {
        // connectManually: true,
        reconnection: true, // (Boolean) whether to reconnect automatically (false)
        reconnectionAttempts: 1 / 0, // (Number, like 5, 10, and infinity etc. ) number of reconnection attempts before giving up (Infinity),
        reconnectionDelay: 10000
      });
    }
    Vue.prototype.$socket.onopen = function(event) {
      // console.log('main js opened::::::::::::::');
      Vue.prototype.$socket.send(JSON.stringify({ 'type': 'get_my_socket_id' }));
      Vue.prototype.$socket = event.target;
      if (to.query.patient_id) {
        var patient_id = to.query.patient_id;
      }
      event.target.send(JSON.stringify({ 'r_n': to.name, 'p_id': patient_id, 'user': user, 'type': 'route_patient' }));
      store.commit('ws_status', true);
      let my_version = JSON.parse(localStorage.getItem('version'));
      VersionService.last_wisdomera_version()
        .then(resp => {
          if (resp.data.status === 'success') {
            if (my_version && my_version.version) {
              if (resp.data.result && resp.data.result.version && my_version.version !== resp.data.result.version) {
                store.commit('version', { 'version_data': resp.data.result });
                localStorage.version = JSON.stringify(resp.data.result);
                console.log('last version changed ', my_version.version, ' => ', resp.data.result.version);
              } else {
                store.commit('version', { 'version_data': my_version });
                // console.log('no version change');
              }
            } else {
              console.log('version information created: ', resp.data.result.version);
              store.commit('version', { 'version_data': resp.data.result });
              localStorage.version = JSON.stringify(resp.data.result);
            }
          } else {
            console.log('version error:', resp.data.message);
          }
        });
    };
  }
  // console.log('to', to);
  if (state.app.device.isMobile && state.app.sidebar.opened) {
    store.commit(TOGGLE_SIDEBAR, false);
  }

  if (auth.user.authenticated || to.name === 'login' || to.name === 'register' || to.name === 'resetpassword') {
    if (to.query && (to.query.wisdom_token_modsiw || to.query.wisdom_refresh_modsiw || to.query.email_approve_token || to.query.email_approve_username)) {
      f_cleanTokenPushRouter(to, from, next);
    } else {
      if (auth.user.authenticated && (to.name === 'register' || to.name === 'resetpassword')) {
        next('/dashboard');
      } else {
        var user = JSON.parse(localStorage.getItem('user'));
        // console.log('main js user::::', user);
        // console.log('vue.prototype', Vue.prototype);
        if (Vue.prototype.$socket) {
          try {
            Vue.prototype.$socket.send(JSON.stringify({ 'r_n': to.name, 'username': user.username, 'type': 'route_patient' }));
          } catch (err) {
            console.log('no connection yet');
          }
        }
        try {
          if (Vue.prototype.$socket) {
            Vue.prototype.$socket.send(JSON.stringify({ 'p_id': 'none', 'username': user.username, 'type': 'route_patient' }));
          }
        } catch (err) {
          console.log('no connection yet');
        }
        next();
      }
    }
  } else {
    if (to.query && to.query.email_approve_token && to.query.email_approve_username) {
      let data = {};
      data.email_approve_username = to.query.email_approve_username;
      data.email_approve_token = to.query.email_approve_token;
      UserService.email_approve(data)
        .then(resp => {
          // console.log('314', resp);
          if (resp.data.status === 'success') {
            f_cleanTokenPushRouter(to, from, next, true, '/login?new_url=');
          } else {
            f_cleanTokenPushRouter(to, from, next, true, '/login?new_url=');
          }
        }), resp => {
          f_cleanTokenPushRouter(to, from, next, true, '/login?new_url=');
        };
    } else {
      if (to.query && to.query.wisdom_token_modsiw && to.query.wisdom_refresh_modsiw) {
        let check_token_data = {}
        check_token_data.token = to.query.wisdom_token_modsiw;
        check_token_data.refresh = to.query.wisdom_refresh_modsiw;
        check_token_data.to = to;
        localStorage.setItem('access_token', check_token_data.token);
        auth.checkAuth();
        LoginService.check_url_token(check_token_data)
          .then(resp => {
            // console.log('314', resp);
            if (resp.data.status === 'success') {
              let token_username = resp.data.result.username;
              if (!localStorage.getItem('presentation')) {
                localStorage.setItem('presentation', false);
              }
              LoginService.login_operation({ 'username': token_username })
                .then(resp => {
                  if (resp.data.status === 'success') {
                    for (let st in resp.data.result.store) {
                      store.commit(st, JSON.stringify(resp.data.result.store[st]));
                    }
                    for (let local in resp.data.result.localStorage) {
                      if (local === 'account_list') {
                        if (!localStorage.getItem('account_list')) {
                          localStorage.setItem(local, JSON.stringify(resp.data.result.localStorage[local]));
                        }
                      } else {
                        localStorage.setItem(local, JSON.stringify(resp.data.result.localStorage[local]));
                      }
                    }
                    store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
                    f_cleanTokenPushRouter(to, from, next);
                  } else {
                    store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
                    f_cleanTokenPushRouter(to, from, next, true, '/login?new_url=');
                  }
                }), resp => {
                  f_cleanTokenPushRouter(to, from, next, true, '/login?new_url=');
                };
            } else {
              f_cleanTokenPushRouter(to, from, next, true, '/login?new_url=');
            }
          }), resp => {
            f_cleanTokenPushRouter(to, from, next, true, '/login?new_url=');
          };
      } else {
        f_cleanTokenPushRouter(to, from, next, true, '/login?new_url=', true);
      }
    }
  }
});

function f_cleanTokenPushRouter(to, from, next, clean_token = false, prefix = '', not_logged_in = false) {
  // console.log('to', to);
  // console.log('from', from);
  // console.log('not_logged_in', not_logged_in);
  if (clean_token) {
    if (localStorage.getItem('user')) {
      localStorage.removeItem('user');
    }
    if (localStorage.getItem('account_list')) {
      localStorage.removeItem('account_list');
    }
    if (localStorage.getItem('profile_picture')) {
      localStorage.removeItem('profile_picture');
    }
    if (localStorage.getItem('access_token')) {
      localStorage.removeItem('access_token');
      auth.checkAuth();
    }
  }
  if (not_logged_in) {
    if (to.meta && (to.meta.wai)) {
      next();
    } else {
      f_cleanTokenPushRouterFinal(to, from, next, clean_token, prefix, not_logged_in)
    }
  } else {
    f_cleanTokenPushRouterFinal(to, from, next, clean_token, prefix, not_logged_in)
  }

}

function f_cleanTokenPushRouterFinal(to, from, next, clean_token, prefix, not_logged_in) {
  if (to.name !== 'Logout') {
    let new_redirect = to.fullPath;
    if (to.query.wisdom_token_modsiw) {
      if (new_redirect.indexOf('&wisdom_token_modsiw=' + to.query.wisdom_token_modsiw) !== -1) {
        new_redirect = new_redirect.replace('&wisdom_token_modsiw=' + to.query.wisdom_token_modsiw, '');
      } else if (new_redirect.indexOf('wisdom_token_modsiw=' + to.query.wisdom_token_modsiw + '&') !== -1) {
        new_redirect = new_redirect.replace('wisdom_token_modsiw=' + to.query.wisdom_token_modsiw + '&', '');
      } else {
        new_redirect = new_redirect.replace('wisdom_token_modsiw=' + to.query.wisdom_token_modsiw, '');
      }
      delete to.query.wisdom_token_modsiw;
    }
    if (to.query.wisdom_refresh_modsiw) {
      if (new_redirect.indexOf('&wisdom_refresh_modsiw=' + to.query.wisdom_refresh_modsiw) !== -1) {
        new_redirect = new_redirect.replace('&wisdom_refresh_modsiw=' + to.query.wisdom_refresh_modsiw, '');
      } else if (new_redirect.indexOf('wisdom_refresh_modsiw=' + to.query.wisdom_refresh_modsiw + '&') !== -1) {
        new_redirect = new_redirect.replace('wisdom_refresh_modsiw=' + to.query.wisdom_refresh_modsiw + '&', '');
      } else {
        new_redirect = new_redirect.replace('wisdom_refresh_modsiw=' + to.query.wisdom_refresh_modsiw, '');
      }
      delete to.query.wisdom_refresh_modsiw;
    }
    if (to.query.email_approve_token) {
      if (new_redirect.indexOf('&email_approve_token=' + to.query.email_approve_token) !== -1) {
        new_redirect = new_redirect.replace('&email_approve_token=' + to.query.email_approve_token, '');
      } else if (new_redirect.indexOf('email_approve_token=' + to.query.email_approve_token + '&') !== -1) {
        new_redirect = new_redirect.replace('email_approve_token=' + to.query.email_approve_token + '&', '');
      } else {
        new_redirect = new_redirect.replace('email_approve_token=' + to.query.email_approve_token, '');
      }
      delete to.query.email_approve_token;
    }
    if (to.query.email_approve_username) {
      if (new_redirect.indexOf('&email_approve_username=' + to.query.email_approve_username) !== -1) {
        new_redirect = new_redirect.replace('&email_approve_username=' + to.query.email_approve_username, '');
      } else if (new_redirect.indexOf('email_approve_username=' + to.query.email_approve_username + '&') !== -1) {
        new_redirect = new_redirect.replace('email_approve_username=' + to.query.email_approve_username + '&', '');
      } else {
        new_redirect = new_redirect.replace('email_approve_username=' + to.query.email_approve_username, '');
      }
      delete to.query.email_approve_username;
    }
    router.push(prefix + new_redirect);
  } else {
    router.push('/login');
  }
}

// ------- LANG ------------
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
// import lang from './lang';
import wdm16 from './wdm16';
// Vue.use(wdm16);
// console.log(Vue.prototype);
// console.log(JSON.stringify(lang));
// console.log(wdm16);
let vue_i18n_data = {
  'locale': 'wdm16',
  'fallbackLocale': 'wdm16',
  'messages': { 'wdm16': wdm16 }
};
const i18n = new VueI18n(vue_i18n_data);
// i18n.setLocaleMessage('en', wdm16);
// console.log(i18n._vm);
// --------------------------

Vue.config.warnHandler = function(msg, vm, trace) {
  // console.log('vue warnHandler: vm => ', vm);
  // console.log('vue warnHandler: msg => ', msg);
  // console.log('vue warnHandler: trace => ', trace.toString());
  if (error_list.indexOf(msg) === -1) {
    error_list.push(msg);
    try {
      let error_data = {
        'codeType': 'frontend',
        'type': 'log',
        'info': trace,
        'sub_type': 'error',
        'typeError': 'frontend',
        'valueError': msg
      };
      // console.log(error_data);
      Vue.http.post(API_BASE_URL + 'log/frontend_log', error_data).then(response => {
        // console.log('response => ', response);
      }, response => {
        // HERE WORKS WHEN WE DONT HAVE AN INTERNET CONNECTION
        console.log('Frontend error sending error. Possible internet connection problem');
      });
    } catch (err) {
      console.log('Vue.config.warnHandler: ', err);
    }
  }
}
/* eslint-disable no-new */
const app = new Vue({
  el: '#app',
  router,
  // nprogress,
  store,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})
export { app, router, store };
